

import React from 'react'
import { render } from 'react-dom'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import Route2 from '../../../../src/spa/pages/[[...route]].jsx'

// import '../../css/index.spa.css'

render(
  <Router>
    <Routes>
      <Route path='*' element={ <Route2 /> }/>
    </Routes>
  </Router>,
  document.getElementById('root')
)
