import React, { useEffect, useState } from 'react'
import { usePagination } from '../../utility'
import itemMapper from './paginationModel'

export const ContactList = ({ page, component }) => {
  const [headline, setHeadline] = useState('')
  const [subtitle, setSubtitle] = useState('')
  const [contactList, setContactList] = useState([])

  const _parameters = component.getParameters()
  const pagination = usePagination({
    page,
    component,
    parameters: _parameters,
    itemMapper: itemMapper(page),
  })

  useEffect(() => {
    const parameters = component?.getParameters()

    const _models = component?.getModels() || {}
    const { document: _documentRef } = _models
    const _document = _documentRef && page.getContent(_documentRef)
    const _data = _document?.getData() || {}

    setHeadline(parameters?.headline)
    setSubtitle(_data?.subtitle)
    setContactList(_data?.contactList)
  }, [page, component])

  let contactDefinitions = pagination?.map((item, index) => (
    <div key={index} className='u-paddingBottom6gu'>
      <dt className='u-textBold u-paddingBottom2gu'>
        <span className='material-icons-round u-marginRight2gu u-posAbsolute contact-icon'>
          {item.icon}
        </span>
        {item.type}
      </dt>
      <dd>{item.value}</dd>
    </div>
  ))

  return (
    <div className='u-size4of6 u-marginLeft4gu'>
      <h2 className='u-sm-textSizeMsrp4 u-textSizeMsrp2 u-textBold u-marginTop7gu u-marginBottom4gu u-textSecondary u-textNoTransform'>
        We're here to help!
      </h2>
      <p className='u-marginBottom4gu'>
        We are available Monday through Friday from 8:00 AM to 6:00 PM ET.
      </p>
      <div className='u-paddingLeft5gu'>
        <div>
          <dl className=' u-posRelative u-paddingLeft4gu'>{contactDefinitions}</dl>
        </div>
      </div>
    </div>
  )
}
