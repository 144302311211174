import React, { useEffect, useRef, useState } from 'react'

function Dropdown({ data, selected, onChange = () => {} }) {
  const [expandDropdown, setExpandDropdown] = useState(false)
  const [showSchoolName, setShowSchoolName] = useState(true)
  const refTuitionCostsDropdown = useRef()

  const showDropdown = () => setExpandDropdown(!expandDropdown)
  const onKeyPressAndOnClick = () => showDropdown(!expandDropdown)
  const onChangeSearchInput = event => {
    setSearch(event.target.value)
  }
  const [selectedItem, setSelectedItem] = useState(selected || {})

  /* FILTER THROUGH SCHOOL DATA */
  const [search, setSearch] = useState('')

  const filterredData =
    data?.filter(
      item => search === '' || item?.label?.toLowerCase()?.includes(search?.toLowerCase())
    ) || []

  const onItemSelect = item => {
    setSelectedItem(item)
    onChange(item)
    setExpandDropdown(false)
    setShowSchoolName(false)
  }

  /* CLICK OUTSIDE DROPDOWN */
  useEffect(() => {
    document.addEventListener('mousedown', event => {
      if (
        refTuitionCostsDropdown &&
        refTuitionCostsDropdown.current &&
        !refTuitionCostsDropdown.current.contains(event.target)
      ) {
        setExpandDropdown(false)
      }
    })
  })

  return (
    <div className='tc-dropdown u-paddingRight2gu' ref={refTuitionCostsDropdown}>
      <div
        className='tc-dropdown__default'
        tabIndex={0}
        onKeyPress={onKeyPressAndOnClick}
        onClick={onKeyPressAndOnClick}
        role='list'
      >
        <div className='tc-dropdown__default--text' role='listitem'>
          {showSchoolName ? <p> Find a school </p> : <p> {selectedItem.label} </p>}
        </div>

        <div className='tc-dropdown__default--icon' role='listitem'>
          {expandDropdown ? (
            <span className='material-icons-round md-30'> arrow_drop_up </span>
          ) : (
            <span className='material-icons-round md-30'> arrow_drop_down </span>
          )}
        </div>
      </div>

      {expandDropdown && (
        <div className='tc-dropdown__expand'>
          <div className='tc-dropdown__expand--search'>
            <div className='tc-dropdown__expand--search__icon'>
              <span className='material-icons-round md-30' aria-label='search icon'>
                search
              </span>
            </div>

            <input
              className='tc-dropdown__expand--search__input'
              type='search'
              placeholder='Search'
              value={search}
              onChange={onChangeSearchInput}
              role='search'
              aria-label='Find a school from the dropdown'
            />
          </div>

          <div className='tc-dropdown__expand--school-names' role='option'>
            {filterredData.length > 0 ? (
              filterredData.map((item, key) => {
                return (
                  <button
                    key={key}
                    type='Button'
                    tabIndex={0}
                    onClick={() => onItemSelect(item)}
                    className='tc-dropdown__expand--school-name__button'
                  >
                    {item.label}
                  </button>
                )
              })
            ) : (
              <p className='tc-dropdown__expand--no-search-results'> No results found. </p>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Dropdown
