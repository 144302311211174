import React from 'react'
import { BrComponent } from '@bloomreach/react-sdk'

const SingleColumn = props => {
  return (
    <div className='u-flex'>
      <BrComponent path='main'>
        <div className={`u-flexAuto`}>
          <BrComponent />
        </div>
      </BrComponent>
    </div>
  )
}
export default SingleColumn
