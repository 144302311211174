import React from 'react'
import { isLocal } from '../../utility/config/configuration'

export const Footer = () => {
  // const FULL_YEAR = new Date().getFullYear()
  const PRIVACY_URL = `https://www.columbiathreadneedleus.com/binaries/content/assets/cti/public/SC_PREPAID_PRIVACY.PDF`
  const IMG_URL = `${isLocal() ? '/site' : ''}/webfiles/latest/assets/scprepaid/images/i4-seal.png`

  return (
    <div className='Footer'>
      <hr className='footer-rule u-sm-hidden' />
      <div className='Grid Grid--withGutter u-paddingTop3gu u-footerConstrained'>
        <div className='u-size1of12 u-sm-size2of12'>
          <img
            className='footer__government-seal'
            alt='Government Seal'
            src={IMG_URL}
            aria-label='Government Seal'
          />
        </div>
        <div className='u-size2of12 u-sm-size4of12 u-flex seal'>
          <p className='mobile-font mobile-bold u-flexJustifyCenter'>
            Curtis M. Loftis, Jr.
            <br />
            State Treasurer
            <br />
            State of South Carolina
          </p>
        </div>
        <div className='u-size9of12 u-sm-size11of12 disclosure'>
          <div className='disclosure mobile-font'>
            <div className='policy'>
              View our <a href={PRIVACY_URL}>privacy policy and internet privacy statement (PDF)</a>
              <br />
              <br />
            </div>
            The information provided in the frequently asked questions section is intended to be
            general guidance related to this program. The answers do not nor are they intended to
            create a contract or any contractual rights nor do they replace or modify the statutes,
            rules, or regulations related to this program. The SCTPP is created by statutory law and
            governed by rules and regulations, and the terms and conditions and benefits of the
            program are prescribed by law. Since these laws and regulations are frequently changed,
            you may obtain the most current information by contacting SCTPP. No representation made
            in the website or by any program official or employee, modifies or expands the benefits
            provided by law.
            <br />
            <br />
            Please consult your attorney or tax advisor concerning any legal questions or tax advice
            related to your situation and the program. The information provided on this website is
            not meant to be tax or investment advice nor do any program officials, employees,
            service providers or agents provide legal, tax or investment advice.
          </div>
        </div>
      </div>
    </div>
  )
  {
    /*}
 return (
   <div className='Footer'>
     <hr className='footer-rule' />
     <div className='web-footer__content mobile-footer__content u-sizeConstrained'>
       <img
         className='footer__government-seal'
         alt='Government Seal'
         src={IMG_URL}
         aria-label='Government Seal'
       />

       <div className='footer__content'>
         <p className='footer__content--text'>
           Please consult your attorney or tax advisor concerning any legal questions or tax advice
           related to your situation and the program. The information provided on this website is
           not meant to be tax or investment advice nor do any program officials, employees,
           service providers or agents provide legal, tax or investment advice.
         </p>

         <p className='footer__content--text'>
           © {FULL_YEAR} South Carolina Tuition Prepayment Program. All Rights Reserved.
           <a
             className='Link'
             href={PRIVACY_URL}
             target='_blank'
             aria-label='Click to view and learn more about SCTTP Privacy Policy and Internet Privacy Statement'
           >
             {' '}
             Privacy Policy and Internet Privacy Statement.{' '}
           </a>
         </p>
       </div>
     </div>
   </div>
 ) */
  }
}
