import React, { useEffect, useState } from 'react'
import { AccordionUnit } from './accordionunit.jsx'
import itemMapper from './paginationModel.js'
import { usePagination } from '../../utility'
import { handleClick, handleScroll } from './helpers/accordion-helpers.js'

export const Accordion = ({ page, component }) => {
  const [data, setData] = useState({
    title: '',
    subtitle: '',
    body: '',
  })

  const [parameters, setParameters] = useState({
    navigation: false,
  })

  const [navPosition, setNavPosition] = useState('covered-expenses')

  const _parameters = component.getParameters()

  const pagination = usePagination({
    page,
    component,
    parameters: _parameters,
    itemMapper: itemMapper(page),
  })

  let isScrolling = false

  const isScrollingCallback = setting => {
    if (typeof setting === 'boolean') {
      isScrolling = setting
    }

    return isScrolling
  }

  useEffect(() => {
    // const _parameters = component?.getParameters()
    const _models = component?.getModels() || {}
    const { document: _documentRef } = _models
    const _document = _documentRef && page.getContent(_documentRef)
    const _data = _document?.getData() || {}

    setData({
      title: _data?.title,
      subtitle: _data?.subtitle,
      html: _data?.html?.value,
    })

    setParameters(_parameters)
  }, [page, component])

  useEffect(() => {
    if (pagination) {
      const itemTypes = [...new Set(Array.from(Object.values(pagination), doc => doc.itemType))]
      const navItems = document.querySelectorAll('.nav-element')
      const accordionSections = document.querySelectorAll('section')

      setNavPosition(itemTypes[0])

      handleClick(navItems, isScrollingCallback, setNavPosition)

      handleScroll(accordionSections, isScrollingCallback, setNavPosition)
    }
  }, [pagination])

  const renderNavigation = () => {
    const itemTypes = [...new Set(Array.from(Object.values(pagination), doc => doc.itemType))]

    return (
      <div className='u-size4of12 u-sm-sizeFull u-sm-marginBottom4gu'>
        <div id='accordion-navigation'>
          <h2 className='u-marginTop4gu u-marginBottom2gu u-textSecondary u-textBold u-textNoTransform u-textSizeMsrp2'>
            Contents
          </h2>
          {itemTypes.map(item => {
            return (
              <div
                key={item}
                className={`nav-element u-paddingVert2gu ${
                  navPosition === item ? 'nav-active' : ''
                }`}
                data-itemtype={item}
              >
                <span
                  className={`u-marginLeft2gu ${
                    navPosition === item ? 'u-adel-reset u-textBold' : ''
                  }`}
                >
                  {item}
                </span>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  const renderAccordion = () => {
    const data = pagination.reduce((prev, curr) => {
      const itemType = curr.itemType

      if (prev[itemType] == null) prev[itemType] = []

      prev[itemType].push(curr)

      return prev
    }, {})

    return (
      <div id='accordion-content'>
        {Object.keys(data).map(itemType => {
          return (
            <section key={itemType} id={itemType}>
              <h3 className='u-marginTop4gu'>{itemType}</h3>
              {data[itemType].map(doc => {
                return <AccordionUnit key={doc.title} doc={doc} />
              })}
            </section>
          )
        })}
      </div>
    )
  }

  return pagination ? (
    <div className='AccordionComponent u-sizeConstrained'>
      <div id='faq-title'>
        <h1 className='u-textSizeMsrp5 u-sm-textSizeMsrp4 u-textBold'>{data.title}</h1>
        <p className='u-marginTop4gu'>{data.subtitle}</p>
      </div>
      <div className='u-flex u-flexWrap'>
        {parameters.navigation && renderNavigation()}
        <div
          className={`${
            parameters.navigation ? 'u-md-size8of12 u-lg-size8of12' : 'u-sizeFull'
          } 'u-sm-sizeFull'`}
        >
          {renderAccordion()}
        </div>
      </div>
    </div>
  ) : null
}
