import React, { useEffect, useState } from 'react'

export const ArticleDefault = ({ page, component, data = {}, parameter }) => {
  return (
    <div className='ArticleDefault'>
      {data.title && (
        <h1 className='u-textSizeMsrp5 u-sm-textSizeMsrp4 u-textBold u-marginBottom4gu'>
          {data.title}
        </h1>
      )}
      {data.subtitle && <p className='u-marginTop4gu '>{data.subtitle}</p>}
      {data.html && (
        <div
          className='article-content u-marginTop4gu'
          dangerouslySetInnerHTML={{ __html: data.html }}
        />
      )}
      {data?.link?.link[0] && (
        <div className='u-marginTop4gu'>
          <a
            className={`${data?.link?.asButton ? 'Button Button--delete' : 'Link'}`}
            target={data?.link?.target}
            href={data?.link.link[0]?.string}
          >
            <span className='u-flex u-flexAlignItemsCenter'>
              {data?.link?.icon && (
                <span className='material-icons-round u-marginRight2gu'> {data?.link?.icon} </span>
              )}
              {data?.link?.text}
            </span>
          </a>
        </div>
      )}
    </div>
  )
}
