import React from 'react'
import { BrComponent } from '@bloomreach/react-sdk'

const TwoColumnWithSubFooter = page => {
  // take a look at page and possible remove
  return (
    <div className='u-sizeConstrained'>
      <div id='headline' className='u-marginBottom5gu'>
        <BrComponent path='headline' />
      </div>
      <div className='Grid u-marginBottom5gu'>
        <div className='u-size1of2 u-sm-sizeFull'>
          <BrComponent path='main' />
        </div>
        <div className='u-size1of2 u-sm-sizeFull'>
          <BrComponent path='sidebar' />
        </div>
      </div>
      <div>
        <BrComponent path='subfooter' />
      </div>
    </div>
  )
}
export default TwoColumnWithSubFooter
