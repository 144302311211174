import React, { useEffect, useState } from 'react'

export const ArticleHighlighted = ({ page, component, data = {}, parameter }) => {
  return (
    <div className='ArticleHightlighted'>
      {data?.title && (
        <>
          <h2 className='u-textSizeMsrp2 u-textBold'>{data.title}</h2>
          <div className='highlighter u-marginTop2gu'></div>
        </>
      )}
      {data.subtitle && <p className='u-marginTop4gu '>{data.subtitle}</p>}
      {data.html && (
        <div
          className='article-content u-marginVert4gu'
          dangerouslySetInnerHTML={{ __html: data.html }}
        />
      )}
      {data?.link?.link[0] && (
        <div className='u-marginTop4gu'>
          <a
            className={`${data?.link?.asButton ? 'Button Button--delete' : 'Link'}`}
            target={data?.link?.target}
            href={data?.link.link[0]?.string}
          >
            <span className='u-flex u-flexAlignItemsCenter'>
              {data?.link?.icon && (
                <span className='material-icons-round u-marginRight2gu'> {data?.link?.icon} </span>
              )}
              {data?.link?.text}
            </span>
          </a>
        </div>
      )}
    </div>
  )
}
