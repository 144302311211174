import SingleColumn from './SingleColumn.jsx'
import DoubleColumn from './DoubleColumn.jsx'
import TwoColumnWithSubFooter from './TwoColumnWithSubFooterSpa.jsx'

const layoutMap = [
  { regex: /two-column-with-sub-footer-spa/, container: TwoColumnWithSubFooter },
  { regex: /doubleColumn/, container: DoubleColumn },
  { regex: /double-container-spa/, container: DoubleColumn },
  { regex: /singleColumn/, container: SingleColumn },
  { regex: /root/, container: SingleColumn },
  { regex: /single-container-spa/, container: SingleColumn },
]
export default layoutMap
