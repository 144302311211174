const itemMapper = page => {
  return itemRef => {
    const item = itemRef && page.getContent(itemRef)
    const data = item.getData()
    return {
      id: data.id,
      type: data.headline,
      to: data.subtitle,
      address: data.address,
      address1: data.address1,
      address2: data.address2,
    }
  }
}
export default itemMapper
