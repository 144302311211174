import React, { useEffect, useState } from 'react'

export const ArticleCTARight = ({ page, component, data = {}, parameter }) => {
  return (
    <div className='ArticleCTARight'>
      <div className='u-flex u-flexWrap u-md-borderBottom u-lg-borderBottom u-sm-paddingLeft0 u-md-paddingLeft0 u-paddingLeft5gu u-paddingBottom4gu u-paddingRight5gu u-sm-paddingTop4gu'>
        <div className='u-size3of4 u-sm-sizeFull u-paddingRight15gu u-sm-paddingRight0'>
          <h2 className='u-textSizeMsrp1 u-textBold'>{data.title}</h2>
          {data.subtitle && <p className='u-marginTop4gu'>{data.subtitle}</p>}
          {data.html && (
            <div
              className='article-content u-marginTop4gu'
              dangerouslySetInnerHTML={{ __html: data.html }}
            />
          )}
        </div>
        <div className='u-size1of4 u-sm-sizeFull u-lg-flex u-lg-flexAlignItemsCenter u-lg-flexJustifyEnd u-sm-marginTop4gu'>
          {data?.link?.link[0] && (
            <a
              className={`${
                data?.link?.asButton ? 'button secondary-button u-lg-size7of12 u-linkClean' : 'Link'
              }`}
              target={data?.link?.target}
              href={data?.link.link[0]?.string}
            >
              <span className='u-lg-flex u-lg-flexAlignItemsCenter u-sm-marginRight4gu'>
                {data?.link?.icon && (
                  <span className='material-icons-round u-marginRight2gu download-icon'>
                    {' '}
                    {data?.link?.icon}{' '}
                  </span>
                )}
                {data?.link?.text}
              </span>
            </a>
          )}
        </div>
      </div>
    </div>
  )
}
