import React, { useState, useEffect, useContext, useRef } from 'react'

import { Link } from 'react-router-dom'
import { OnClickScrollToTop } from '../../../components/Global/OnClickScrollToTop'
import { getPathAndEndpoint } from '../../utility/config/configuration'

export const FooterMenu = ({ page, component }) => {
  const [menu, setMenu] = useState(null)

  const refMobileFooterNavigation = useRef()
  const [mobileNavigationSelected, setMobileNavigationSelected] = useState('')

  useEffect(() => {
    const _menuRef = component?.getModels().menu
    const _menu = _menuRef && page?.getContent(_menuRef)
    if (_menu) {
      setMenu(_menu)
    }
  }, [page, component])

  /* CLICK OUTSIDE FOOTER NAVIGATION */
  useEffect(() => {
    document.addEventListener('mousedown', event => {
      if (
        refMobileFooterNavigation &&
        refMobileFooterNavigation.current &&
        !refMobileFooterNavigation.current.contains(event.target)
      ) {
        setMobileNavigationSelected('')
      }
    })
  })

  const onClickExpandNavigation = event => {
    const mobileMenuSelected = event.currentTarget.dataset.navigation
    if (mobileNavigationSelected === mobileMenuSelected) {
      setMobileNavigationSelected('')
    } else {
      setMobileNavigationSelected(mobileMenuSelected)
    }
  }

  const onClickHideNavigationOptions = () => {
    setMobileNavigationSelected('')
  }

  return (
    <div className='FooterMenu'>
      <div className='footer web-footer'>
        <div
          className='web-footer__navigation mobile-footer__navigation u-sizeConstrained'
          style={{ gridTemplateColumns: `repeat(${menu?.getItems()?.length}, 1fr)` }}
        >
          {menu?.getItems().map((item, index) => (
            <WebMenuItem key={index} item={item} page={page} isRoot />
          ))}
        </div>
      </div>
      <div className='footer mobile-footer'>
        <div ref={refMobileFooterNavigation}>
          {menu?.getItems().map((item, index) => (
            <MobileMenuItem
              key={index}
              item={item}
              page={page}
              mobileNavigationSelected={mobileNavigationSelected}
              onClickExpandNavigation={onClickExpandNavigation}
              isRoot
            />
          ))}
        </div>
      </div>
    </div>
  )
}

const WebMenuItem = ({ item, page, isRoot }) => {
  const name = item.getName()
  const description = item.getParameters()?.description
  const link = item.getLink()
  const childs = item.getChildren()

  return (
    <>
      <div className='web-footer__navigation--group mobile-footer__navigation--group'>
        {link ? (
          isRoot && description ? (
            <>
              <h2 className='u-textSecondary u-textBold u-textNoTransform u-textSizeMsrp2'>
                {name}
              </h2>
              <a
                className='Linkt'
                href={`${getPathAndEndpoint().path}${link?.href}`}
                onClick={OnClickScrollToTop}
                aria-label={description}
              >
                {description}
              </a>
            </>
          ) : (
            <a
              className='Link'
              href={`${getPathAndEndpoint().path}${link?.href}`}
              onClick={OnClickScrollToTop}
              aria-label={name}
            >
              {name}
            </a>
          )
        ) : (
          <h2 className='u-textSecondary u-textBold u-textNoTransform u-textSizeMsrp2'>{name}</h2>
        )}

        <div>
          {childs?.map((child, index) => (
            <WebMenuItem key={index} item={child} page={page} />
          ))}
        </div>
      </div>
    </>
  )
}

const MobileMenuItem = ({
  item,
  page,
  onClickExpandNavigation,
  mobileNavigationSelected,
  isRoot,
}) => {
  const name = item.getName()
  const description = item.getParameters()?.description
  const link = item.getLink()
  const childs = item.getChildren()

  const showItemDetails = () => {
    return (
      <>
        {link ? (
          isRoot ? (
            <>
              <div className='mobile-footer__navigation'>
                <div className='mobile-footer__navigation--single-link'>
                  <a
                    className='mobile-footer__navigation--single-link__route'
                    href={`${getPathAndEndpoint().path}${link?.href}`}
                    onClick={OnClickScrollToTop}
                    aria-label={name}
                  >
                    {name}
                  </a>
                </div>
              </div>
            </>
          ) : (
            <a
              className='footer-link mobile-footer__navigation--link__route'
              href={`${getPathAndEndpoint().path}${link?.href}`}
              onClick={OnClickScrollToTop}
              aria-label={name}
            >
              {name}
            </a>
          )
        ) : (
          <button
            className='nav-button mobile-footer__navigation--button u-sm-paddingVert4gu'
            onClick={onClickExpandNavigation}
            data-navigation={name}
          >
            {' '}
            {name}
            {mobileNavigationSelected === { name } ? (
              <span
                className='material-icons-round md-30'
                aria-label='Click to expand less and hide menu options'
              >
                {' '}
                expand_less{' '}
              </span>
            ) : (
              <span
                className='material-icons-round md-30'
                aria-label='Click to expand more to see menu options'
              >
                {' '}
                expand_more{' '}
              </span>
            )}
          </button>
        )}
        <hr className='footer-rule' />
      </>
    )
  }

  const showChilds = () => {
    return (
      <>
        {mobileNavigationSelected === name ? (
          <div className='mobile-footer__navigation--link'>
            {childs?.map((child, index) => (
              <MobileMenuItem key={index} item={child} page={page} />
            ))}
          </div>
        ) : (
          ''
        )}
      </>
    )
  }

  return (
    <>
      {childs?.length > 0 ? (
        <div className='mobile-footer__navigation'>
          {showItemDetails()}
          {showChilds()}
        </div>
      ) : (
        <>{showItemDetails()}</>
      )}
    </>
  )
}
