import React, { useEffect, useState } from 'react'

export const ArticleBanner = ({ page, component, data, parameter }) => {
  return (
    <div className='ArticleBanner Grid'>
      <div className='u-sm-size1of1 u-md-size1of1 u-lg-size1of2'>
        <h1 className='u-textSizeMsrp4 u-textBold'>{data.title}</h1>
        {data.subtitle && <p className='u-marginTop4gu'>{data.subtitle}</p>}
        <div
          className='article-content u-marginTop4gu u-marginRight5gu'
          dangerouslySetInnerHTML={{ __html: data.html }}
        />
        {data?.link?.link[0] && (
          <div className='u-marginTop4gu'>
            <a
              className={`${
                data?.link?.asButton
                  ? 'Button Button--delete u-sm-sizeFull u-md-sizeFull button secondary-button u-size2of8'
                  : 'Link'
              } `}
              target={data?.link?.target}
              href={data?.link.link[0]?.string}
            >
              <span className='u-flex u-flexAlignItemsCenter'>
                {data?.link?.icon && (
                  <span className='material-icons-round u-marginRight2gu'>
                    {' '}
                    {data?.link?.icon}{' '}
                  </span>
                )}
                {data?.link?.text}
              </span>
            </a>
          </div>
        )}
      </div>

      <div className='u-sm-size1of1 u-md-size1of1 u-lg-size1of2'>
        <img
          alt={data.imageDescription}
          src={data.imageUrl}
          className='u-sizeFull article-picture'
        />
      </div>
    </div>
  )
}
