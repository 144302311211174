import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { CONTACT_US_LINK, USER_LOGIN_LINK, ADMIN_LOGIN_LINK } from '../../utility/links/links'
import { isLocal, getPathAndEndpoint } from '../../utility/config/configuration'
import { encodeForURL } from '../../../utils/formatter/sanitize-url'
import DOMPurify from 'dompurify'

export const Header = () => {
  const refLoginExpand = useRef()
  const [showLoginExpand, setShowLoginExpand] = useState(false)
  const onClickLoginButton = () => {
    showLoginExpand === true ? setShowLoginExpand(false) : setShowLoginExpand(true)
  }
  const IMG_URL = `${
    isLocal() ? '/site' : ''
  }/webfiles/latest/assets/scprepaid/images/scprepaid_logo.gif`

  /* CLICK OUTSIDE LOGIN EXPAND */
  useEffect(() => {
    document.addEventListener('mousedown', event => {
      if (
        refLoginExpand &&
        refLoginExpand.current &&
        !refLoginExpand.current.contains(event.target)
      ) {
        setShowLoginExpand(false)
      }
    })
  })

  return (
    <div className='Header top-header u-sizeConstrained'>
      <div className='top-header__container'>
        <div className='top-header__container--mobile u-paddingHoriz5gu'>
          <a href={DOMPurify.sanitize(encodeURI(getPathAndEndpoint().path || '/'))}>
            <img
              className='top-header__container--logo'
              aria-label='Click to go to the Home Page'
              alt='South Carolina Tuition Prepayment Program Logo'
              src={IMG_URL}
            />
          </a>
        </div>

        <div className='top-header__container--buttons'>
          <div className='top-header__container--buttons__contact-us'>
            <button
              className='button secondary-button top-header__container--buttons__contact-us--button'
              onClick={() => window.open(CONTACT_US_LINK, '_self')}
            >
              {' '}
              Contact Us{' '}
            </button>
          </div>

          <div className='top-header__container--buttons__login' ref={refLoginExpand}>
            <button className='button primary-button' onClick={onClickLoginButton}>
              {' '}
              Login{' '}
            </button>
            {showLoginExpand && <LoginExpand />}
          </div>
        </div>
      </div>
    </div>
  )
}

function LoginExpand() {
  return (
    <div className='login-expand'>
      <div className='login-expand__triangle login-expand__inner-triangle'></div>
      <div className='login-expand__triangle login-expand__outer-triangle'></div>

      <div className='login-expand__group'>
        <button
          className='button primary-button'
          onClick={() => window.open(USER_LOGIN_LINK, '_blank')}
        >
          {' '}
          SCTPP Customer Login{' '}
        </button>

        <div className='login-expand__group--rules'>
          <hr className='login-rule' />
          <p> OR </p>
          <hr className='login-rule' />
        </div>

        <button
          className='button primary-button'
          onClick={() => window.open(ADMIN_LOGIN_LINK, '_blank')}
        >
          {' '}
          School Administrator Login{' '}
        </button>
      </div>
    </div>
  )
}
