import React, { useEffect, useState } from 'react'
import { ArticleBanner } from './articlebanner.jsx'
import { ArticleCTARight } from './articlectaright.jsx'
import { ArticleDefault } from './articledefault.jsx'
import { ArticleHighlighted } from './articlehighlighted.jsx'
import { ArticleLeftHighlighted } from './articlelefthighlighted.jsx'

export const ArticleDetail = ({ page, component, item }) => {
  const STYLES = {
    BANNER: 'Banner',
    DEFAULT: 'Defualt',
    HIGHLIGHTED: 'Highlighted',
    LEFT_HIGHLIGHTED: 'LeftHighlighted',
    CTA_RIGHT: 'CtaRight',
  }

  const [data, setData] = useState({
    title: '',
    subtitle: '',
    html: '',
    link: null,
    imageUrl: null,
    imageDescription: null,
  })

  const [parameters, setParameters] = useState({
    style: STYLES.DEFAULT,
  })

  useEffect(() => {
    if (item) {
      setData(item)
    } else {
      const _models = component?.getModels() || {}
      const { document: _documentRef } = _models
      const _document = _documentRef && page.getContent(_documentRef)
      const _data = _document?.getData() || {}
      const { linkCompound = {} } = _data
      const [link = {}] = linkCompound?.link || []

      setData({
        title: _data.title,
        subtitle: _data.subtitle,
        html: _data.html?.value,
        link: _data?.linkCompound,
        imageUrl: page?.getContent(_data?.imageCompound?.image)?.getOriginal()?.getUrl(),
        imageDescription: _data?.imageCompound?.alt,
      })
    }

    const _parameters = component?.getParameters()
    setParameters(_parameters)
  }, [page, component, item])

  const showArticle = () => {
    switch (parameters.style) {
      case STYLES.BANNER:
        return (
          <ArticleBanner page={page} component={component} data={data} parameters={parameters} />
        )
      case STYLES.HIGHLIGHTED:
        return (
          <ArticleHighlighted
            page={page}
            component={component}
            data={data}
            parameters={parameters}
          />
        )
      case STYLES.LEFT_HIGHLIGHTED:
        return (
          <ArticleLeftHighlighted
            page={page}
            component={component}
            data={data}
            parameters={parameters}
          />
        )
      case STYLES.DEFAULT:
        return (
          <ArticleDefault page={page} component={component} data={data} parameters={parameters} />
        )
      case STYLES.CTA_RIGHT:
        return (
          <ArticleCTARight page={page} component={component} data={data} parameters={parameters} />
        )
      default:
        return (
          <ArticleDefault page={page} component={component} data={data} parameters={parameters} />
        )
    }
  }

  return <div className={`Article ${item ? '' : 'u-sizeConstrained'}`}>{showArticle()}</div>
}
