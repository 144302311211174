const itemMapper = page => {
  return itemRef => {
    const item = itemRef && page.getContent(itemRef)
    const data = item.getData()
    return {
      title: data.title,
      itemType: data.itemType,
      subtitle: data.subtitle,
      html:
        data.html && typeof data.html === 'object' ? data.html.value : data.html || data.content,
      document: item,
    }
  }
}
export default itemMapper
