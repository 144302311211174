import React, { useEffect, useState } from 'react'

export const AccordionUnit = ({ doc }) => {
  const [isActive, setIsActive] = useState(false)

  return (
    <div
      key={doc.title}
      className='accordion-unit u-border u-borderColorNeutralLight u-borderLeft0 u-borderRight0 u-borderTop0'
    >
      {doc.title ? (
        <div className='u-marginLeft8gu'>
          <div className='u-flex u-flexAlignItemsCenter' onClick={() => setIsActive(!isActive)}>
            <p className='u-marginVert4gu u-size7of8'>{doc.title}</p>
            <div className='u-flex u-flexJustifyEnd u-size1of8'>
              <span
                className={`material-icons-round u-marginLeft2gu u-textSizeMsrp4 ${
                  isActive && 'u-textColorAlert'
                }`}
              >
                {isActive ? 'expand_less' : 'expand_more'}
              </span>
            </div>
          </div>
          {isActive && (
            <div
              className='u-marginVert4gu u-size7of8 set-html-list'
              dangerouslySetInnerHTML={{ __html: doc.html }}
            />
          )}
        </div>
      ) : (
        <div className='u-flex u-flexAlignItemsCenter u-marginLeft8gu'>
          <div
            className='u-marginVert4gu u-size7of8'
            dangerouslySetInnerHTML={{ __html: doc.html }}
          />
        </div>
      )}
    </div>
  )
}
