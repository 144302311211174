const itemMapper = page => {
  return itemRef => {
    const item = itemRef && page.getContent(itemRef)
    const data = item.getData()
    return {
      type: data.contactType,
      value: data.contactValue,
      icon: data.staticdropdown,
    }
  }
}
export default itemMapper
