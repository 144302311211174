import React, { useEffect, useState } from 'react'
import { usePagination } from '../../utility'
import { ArticleDetail } from '../article/article.jsx'
import itemMapper from './paginationModel'

export const ArticleList = ({ page, component }) => {
  const LAYOUT = {
    LIST: 'List',
    GALLERY: 'Gallery',
  }

  const [data, setData] = useState({
    title: '',
    subtitle: '',
    body: '',
  })

  const [parameters, setParameters] = useState({
    layout: LAYOUT.ONECOLUMN,
  })

  const _parameters = component.getParameters()
  const pagination = usePagination({
    page,
    component,
    parameters: _parameters,
    itemMapper: itemMapper(page),
  })

  useEffect(() => {
    const _parameters = component?.getParameters()
    const _models = component?.getModels() || {}
    const { document: _documentRef } = _models
    const _document = _documentRef && page.getContent(_documentRef)
    const _data = _document?.getData() || {}

    setData({
      title: _data?.title,
      subtitle: _data?.subtitle,
      html: _data?.html?.value,
    })

    setParameters(_parameters)
  }, [page, component])

  return (
    <div className='ArticleList u-sizeConstrained'>
      {data.title && (
        <h1 className='u-textSizeMsrp5 u-sm-textSizeMsrp4 u-textBold u-sm-paddingBottom3gu u-md-paddingBottom3gu u-marginBottom4gu'>
          {data.title}
        </h1>
      )}
      {data.subtitle && <p className='u-marginTop5gu u-marginBottom4gu'>{data.subtitle}</p>}
      {data.html && (
        <div
          className='article-content u-marginTop4gu'
          dangerouslySetInnerHTML={{ __html: data.html }}
        />
      )}
      <div className='u-flex u-flexRow u-flexWrap u-paddingTop4gu u-paddingBottom4gu'>
        {pagination?.map((item, index) => (
          <div
            key={index}
            className={`u-sm-sizeFull u-sm-paddingRight0 u-md-paddingTop2gu u-lg-paddingTop2gu u-sm-paddingLeft0 u-sm-marginBottom4gu u-sm-paddingBottom4gu u-sm-borderBottom ${
              parameters.layout === LAYOUT.GALLERY
                ? index % 2 === 0
                  ? 'u-size1of2 u-paddingRight8gu'
                  : 'u-size1of2 u-paddingLeft8gu'
                : ''
            } ${parameters.layout === LAYOUT.LIST ? 'u-sizeFull u-marginBottom4gu' : ''}`}
          >
            <ArticleDetail page={page} component={component} item={item} />
          </div>
        ))}
      </div>
    </div>
  )
}
