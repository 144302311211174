const itemMapper = page => {
  return itemRef => {
    const item = itemRef && page.getContent(itemRef)
    const data = item.getData()
    return {
      id: data.id,
      value: data.displayName,
      itemType: data.itemType,
      title: data.title,
      subtitle: data.subtitle,
      link: data.linkCompound,
      document: item,
    }
  }
}
export default itemMapper
