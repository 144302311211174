export const handleClick = (navItems, isScrollingCallback, setNavPosition) => {
  navItems.forEach(navItem => {
    navItem.addEventListener('click', event => {
      event.preventDefault()

      let target = event.currentTarget

      // Announce that we're scrolling so other scroll events won't run
      isScrollingCallback(true)

      setNavPosition(target.dataset.itemtype)

      // Ensure current item is focused (for accessibility)
      setTimeout(() => target.parentElement.focus(), 100)

      const headerHeightOffset = document.querySelector('header')?.clientHeight * 0.9

      // Scroll to clicked section
      let scrollTo = document.getElementById(target.dataset.itemtype).offsetTop + headerHeightOffset

      const step = () => {
        window.scrollTo({
          top: scrollTo,
          left: 0,
          behavior: 'smooth',
        })

        // Announce that we're done scrolling
        setTimeout(() => isScrollingCallback(false), 1000)
      }

      window.requestAnimationFrame(step)
    })
  })
}

export const handleScroll = (listItems, isScrollingCallback, setNavPosition) => {
  const sections = listItems

  let timeout

  const setActiveItem = () => {
    let current = ''
    const margin = 20
    const headerHeightOffset = document.querySelector('header')?.clientHeight * 0.9

    sections.forEach(section => {
      const sectionTop = section.offsetTop

      if (scrollY <= sections[1].offsetTop + headerHeightOffset) {
        current = sections[0].getAttribute('id')
      } else if (
        scrollY > sectionTop + headerHeightOffset - margin &&
        scrollY <= sectionTop + headerHeightOffset + section.clientHeight
      ) {
        current = section.getAttribute('id')
      } else if (scrollY > sections[sections.length - 1].offsetTop + headerHeightOffset) {
        current = sections[sections.length - 1].getAttribute('id')
      }
    })

    setNavPosition(current)
  }

  const doScroll = () => {
    // Update left nav (but only if we're scrolling from mouse, not from clicking left nav)
    if (timeout || isScrollingCallback()) {
      return
    }

    timeout = setTimeout(() => {
      setActiveItem()

      timeout = null
    }, 250)
  }

  doScroll()

  window.addEventListener('scroll', doScroll)
}
