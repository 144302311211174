export const getLocalPath = () => {
  return getPathAndEndpoint().path
}

export const getPathAndEndpoint = () => {
  if (window.location.pathname.startsWith('/site')) {
    const pathSplit = window.location.pathname.split('/')

    if (pathSplit[2] === '_cmsinternal') {
      return {
        path: `/${pathSplit[1]}/${pathSplit[2]}/${pathSplit[3]}`,
        endpoint: `/${pathSplit[1]}/${pathSplit[3]}`,
      }
    } else if (pathSplit[3] === 'preview') {
      return {
        path: `/${pathSplit[1]}/${pathSplit[2]}/${pathSplit[3]}`,
        endpoint: `/${pathSplit[1]}/${pathSplit[2]}/${pathSplit[3]}`,
      }
    } else {
      return {
        path: `/${pathSplit[1]}/${pathSplit[2]}`,
        endpoint: `/${pathSplit[1]}/${pathSplit[2]}`,
      }
    }
  } else if (window.location.pathname.startsWith('/scprepaid/preview')) {
    return {
      path: `/scprepaid/preview`,
      endpoint: `/scprepaid/preview`,
    }
  } else if (window.location.pathname.startsWith('/scprepaid')) {
    return {
      path: `/scprepaid`,
      endpoint: `/scprepaid`,
    }
  } else if (window.location.pathname.startsWith('/preview')) {
    return {
      path: `/preview`,
      endpoint: `/preview`,
    }
  }

  return {
    path: '',
    endpoint: '',
  }
}

export const getSiteName = () => {
  if (window.location.pathname.startsWith('/site')) {
    return getLocalPath().replace('/site/', '').replace('/preview', '')
  } else if (
    window.location.pathname.startsWith('/scprepaid') ||
    window.location.pathname.startsWith('/preview/scprepaid')
  ) {
    return `scprepaid`
  }

  return ''
}

export const isLocal = () => {
  return window.location.port
}

export const isCms = () => {
  return false
}

export const isDev = () => {
  return window.location.origin.indexOf('www.dev') > -1
}

export const isQa = () => {
  return window.location.origin.indexOf('www.qa') > -1
}

export const isQaWs3 = () => {
  return window.location.origin.indexOf('www-ws3.qa') > -1
}

export const isPreview = () => {
  return window.location.origin.indexOf('www-preview.') > -1
}

export const isPreprod = () => {
  return window.location.origin.indexOf('www-preprod.') > -1
}

export const isProduction = () => {
  return (
    window.location.origin.indexOf('www.scprepaid') > -1 ||
    window.location.origin.indexOf('www-preview.') > -1 ||
    window.location.origin.indexOf('www-preprod.') > -1
  )
}

export const getDebugConfig = () => {
  let endpoint

  if (isLocal()) {
    endpoint = 'http://localhost:8081' + getPathAndEndpoint().endpoint + '/resourceapi'
  } else if (isDev()) {
    endpoint = 'https://www.dev.scprepaid.com' + getPathAndEndpoint().endpoint + '/resourceapi'
  } else if (isQaWs3()) {
    endpoint = 'https://www-ws3.qa.scprepaid.com' + getPathAndEndpoint().endpoint + '/resourceapi'
  } else if (isQa()) {
    endpoint = 'https://www.qa.scprepaid.com' + getPathAndEndpoint().endpoint + '/resourceapi'
  } else if (isPreview()) {
    endpoint = 'https://www-preview.scprepaid.com' + getPathAndEndpoint().endpoint + '/resourceapi'
  } else if (isPreprod()) {
    endpoint = 'https://www-preprod.scprepaid.com' + getPathAndEndpoint().endpoint + '/resourceapi'
  } else if (isProduction()) {
    endpoint = 'https://www.scprepaid.com' + getPathAndEndpoint().endpoint + '/resourceapi'
  }

  let origin

  if (isLocal()) {
    origin = undefined
  } else if (isDev()) {
    origin = 'https://cms.dev.ameriprise.com'
  } else if (isQaWs3()) {
    origin = 'https://cms-ws3.qa.ameriprise.com'
  } else if (isQa()) {
    origin = 'https://cms.qa.ameriprise.com'
  } else if (isPreview() || isPreprod() || isProduction()) {
    origin = 'https://cms.ameriprise.com'
  }

  return {
    PATH: `${window.location.pathname.replace(getLocalPath(), '')}${window.location.search}`,
    ENDPOINT: endpoint,
    DEBUG: true,
    ORIGIN: origin,
  }
}
