import React, { useEffect, useState } from 'react'
import { usePagination } from '../../utility'
import itemMapper from './paginationModel'
import { TabContent } from './TabContent.jsx'
export const TabList = ({ page, component }) => {
  const [data, setData] = useState({
    title: '',
    subTitle: '',
  })

  const [parameters, setParameters] = useState({})
  const _parameters = component.getParameters()
  const pagination = usePagination({
    page,
    component,
    parameters: _parameters,
    itemMapper: itemMapper(page),
  })

  useEffect(() => {
    const _models = component?.getModels() || {}
    const { document: _documentRef } = _models
    const _document = _documentRef && page.getContent(_documentRef)
    const _data = _document?.getData?.() || {}

    setData({
      title: _data.title,
      subTitle: _data.subTitle,
    })

    const _parameters = component?.getParameters()
    setParameters(_parameters)
  }, [page, component])

  //sets active state of clicked tab
  const [active, setActive] = useState('Annual')
  let reports = [],
    allAnnualReports = [],
    allActurialReports = []

  //object containing all reports
  pagination?.map(item => {
    reports.push(item)
  })

  //store reports in sperate objects
  for (let key in pagination) {
    if (pagination?.[key].itemType === 'Annual') {
      allAnnualReports.push(pagination?.[key])
    }
    if (pagination?.[key].itemType === 'Actuarial') {
      allActurialReports.push(pagination?.[key])
    }
  }

  return (
    <div className='TabList u-sizeConstrained u-marginBottom4gu u-marginTop1gu'>
      <h1 className='u-textSizeMsrp3 u-md-textSizeMsrp5 u-lg-textSizeMsrp5 u-marginBottom6gu'>
        {data.title}
      </h1>
      <div className='Button-group u-marginBottom5gu'>
        <h2
          className={`${
            active === allAnnualReports[0]?.itemType ? 'active' : ''
          } Button TabButton u-textSizeMsrp0 u-md-textSizeMsrp2 u-lg-textSizeMsrp2 u-textBold u-marginRight4gu u-textUppercase`}
          onClick={() => setActive(allAnnualReports[0]?.itemType)}
        >
          {allAnnualReports[0]?.itemType}
          <span className='u-sm-hidden u-textSecondary'> REPORTS</span>
        </h2>
        <h2
          className={`${
            active === allActurialReports[0]?.itemType ? 'active' : ''
          } Button TabButton u-textSizeMsrp0 u-md-textSizeMsrp2 u-lg-textSizeMsrp2  u-textBold u-marginRight4gu u-textUppercase`}
          onClick={() => setActive(allActurialReports[0]?.itemType)}
        >
          {allActurialReports[0]?.itemType}
          <span className='u-sm-hidden u-textSecondary'> REPORTS</span>
        </h2>
      </div>
      <div className='TabList-content-items'>
        {active === allAnnualReports[0]?.itemType && (
          <TabContent activeArticles={allAnnualReports} allReports={reports} />
        )}
        {active === allActurialReports[0]?.itemType && (
          <TabContent activeArticles={allActurialReports} allReports={reports} />
        )}
      </div>
    </div>
  )
}
