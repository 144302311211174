import React, { useEffect, useState } from 'react'
import { usePagination } from '../../utility'
import Dropdown from './dropdown.jsx'
import itemMapper from '../articlelist/paginationModel'

export const Table = ({ page, component }) => {
  const LAYOUT = {
    DEFAULT: 'Default',
    DROPDOWN: 'Dropdown',
  }

  const [data, setData] = useState({
    title: '',
    subTitle: '',
    tableCompound: '',
    html: '',
  })

  const [parameters, setParameters] = useState({
    layout: LAYOUT.DEFAULT,
    columns: null,
    dropdownPlaceholder: null,
  })

  const [selectedDropdownItem, setSelectedDropdownItem] = useState()

  const _parameters = component.getParameters()
  const pagination = usePagination({
    page,
    component,
    parameters: _parameters,
    itemMapper: itemMapper(page),
  })

  useEffect(() => {
    const _models = component?.getModels() || {}
    const { document: _documentRef } = _models
    const _document = _documentRef && page.getContent(_documentRef)
    const _data = _document?.getData() || {}

    setData({
      title: _data.title,
      subTitle: _data.subTitle,
      html: _data.html?.value,
    })

    const _parameters = component?.getParameters()
    setParameters(_parameters)
  }, [page, component])

  const onDropdownItemChange = selectedItem => {
    const rowData =
      pagination
        ?.filter(item => {
          const { field1 } = item.document.getData()
          return field1 === selectedItem.id
        })
        ?.map(item => {
          const { field1, field2, field3, field4 } = item.document.getData()
          return {
            field1,
            field2,
            field3,
            field4,
          }
        }) || null

    setSelectedDropdownItem(rowData[0])
  }

  const showData = () => {
    const items = pagination?.map(item => {
      const { field1, field2, field3, field4 } = item.document.getData()
      return {
        field1,
        field2,
        field3,
        field4,
      }
    })

    if (parameters.layout === LAYOUT.DROPDOWN) {
      return renderDropdownTableData(items)
    } else {
      return renderTableData(items)
    }
  }

  const renderTableData = items => {
    return (
      <table className='Table'>
        <thead className='Table-head'>
          <tr className='Table-row'>
            {parameters.columns?.split('||')?.map((item, index) => (
              <th className='Table-header u-alignMiddle' key={index}>
                {' '}
                <h2 className='u-textSecondary u-textBold u-textNoTransform u-textSizeMsrp1 u-textColorWhite u-textCenter'>
                  {' '}
                  {item}{' '}
                </h2>{' '}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className='Table-body'>
          {items?.map((item, index) => (
            <tr key={index} className='Table-row'>
              {parameters.columns?.split('||')?.map((val, key) => (
                <td key={index + '-' + key} className='Table-cell u-padding3gu u-textCenter'>
                  {item[`field${key + 1}`]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    )
  }

  const renderDropdownTableData = items => {
    const dropdownData = items.map(item => {
      return {
        id: item.field1,
        label: item.field1,
      }
    })

    const placeHolders = parameters?.dropdownPlaceholder?.split('||')

    return (
      <>
        <table className='Table Table--inbox tc-desktop'>
          <thead className='Table-head'>
            <tr className='Table-row'>
              {parameters.columns?.split('||')?.map((item, index) => (
                <th className='Table-header u-alignMiddle' key={index}>
                  {' '}
                  <h2 className='u-textSecondary u-textBold u-textNoTransform u-textSizeMsrp1 u-textColorWhite'>
                    {' '}
                    {item}{' '}
                  </h2>{' '}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='Table-body'>
            <tr className='Table-row'>
              {parameters.columns?.split('||').map((val, key) => (
                <td key={key} className='Table-cell u-textCenter'>
                  {key === 0 ? (
                    <Dropdown
                      data={dropdownData}
                      onChange={onDropdownItemChange}
                      selected={
                        (selectedDropdownItem?.field1 && {
                          id: selectedDropdownItem?.field1,
                          label: selectedDropdownItem?.field1,
                        }) ||
                        undefined
                      }
                      placeHolder={placeHolders[0]}
                    />
                  ) : (
                    <>
                      {(selectedDropdownItem && selectedDropdownItem[`field${key + 1}`]) ||
                        placeHolders[key] ||
                        ''}
                    </>
                  )}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
        <div class='tc-mobile-table'>
          <Dropdown
            data={dropdownData}
            onChange={onDropdownItemChange}
            selected={
              (selectedDropdownItem?.field1 && {
                id: selectedDropdownItem?.field1,
                label: selectedDropdownItem?.field1,
              }) ||
              undefined
            }
            placeHolder={placeHolders[0]}
          />
          <hr class='content-full-width-horizontal-rule'></hr>
          {parameters.columns
            ?.split('||')
            ?.filter((item, index) => index > 0)
            .map((item, index) => (
              <>
                <div class='tc-mobile-table__group'>
                  <h3 className='heading'> {item} </h3>
                  <div class='data u-flex u-flexJustifyEnd u-flexAlignItemsCenter'>
                    {(selectedDropdownItem && selectedDropdownItem[`field${index + 2}`]) ||
                      placeHolders[index + 1] ||
                      ''}
                  </div>
                </div>
                <hr class='content-full-width-horizontal-rule'></hr>
              </>
            ))}
        </div>
      </>
    )
  }

  return (
    <div className='TableComponent u-sizeConstrained u-marginBottom4gu'>
      {data.title && (
        <h1 className='u-textSizeMsrp5 u-sm-textSizeMsrp4 u-textBold u-marginBottom4gu'>
          {data.title}
        </h1>
      )}
      <p className='u-marginTop4gu'>{data.subTitle}</p>

      <div className='u-marginTop4gu' dangerouslySetInnerHTML={{ __html: data?.html }} />

      <div className='Table-overflowWrapper u-marginTop4gu'>{showData()}</div>
    </div>
  )
}
