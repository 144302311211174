import React, { useEffect, useState } from 'react'

export const ArticleLeftHighlighted = ({ page, component, data = {}, parameter }) => {
  return (
    <div className='ArticleHightlighted'>
      <div className='u-flex u-flexWrap u-paddingTop4gu u-paddingBottom6gu u-paddingHoriz5gu u-md-borderBottom u-lg-borderBottom '>
        <div className='u-size5of12 u-sm-sizeFull u-paddingRight15gu u-sm-paddingRight0'>
          <h2 className='u-textSizeMsrp1 u-textBold'>{data.title}</h2>
          <div className='highlighter u-marginTop2gu'></div>
        </div>
        <div className='u-size7of12 u-sm-sizeFull'>
          {data.subtitle && <p>{data.subtitle}</p>}
          {data?.link?.link[0] && (
            <div className='u-marginTop4gu'>
              <a
                className={`${
                  data?.link?.asButton
                    ? 'button secondary-button u-lg-size6of12 u-linkClean'
                    : 'Link'
                }`}
                target={data?.link?.target}
                href={data?.link.link[0]?.string}
              >
                <span className='u-flex u-flexAlignItemsCenter'>
                  {data?.link?.icon && (
                    <span className='material-icons-round u-marginRight2gu'>
                      {' '}
                      {data?.link?.icon}{' '}
                    </span>
                  )}
                  {data?.link?.text}
                </span>
              </a>
            </div>
          )}
          {data.html && (
            <div
              className='article-content u-marginTop4gu'
              dangerouslySetInnerHTML={{ __html: data.html }}
            />
          )}
        </div>
      </div>
    </div>
  )
}
