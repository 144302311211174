import { useState, useEffect } from 'react'

export function usePagination({ page, component, parameters, itemMapper }) {
  const [items, setItems] = useState()

  function getItems(page, component) {
    const models = component.getModels()
    if (!models?.pagination) {
      throw new Error('pagination not defined on the component model')
    }

    const { pagination: _paginationRef } = models

    const pagination = _paginationRef && page.getContent(_paginationRef)
    if (!pagination) {
      throw new Error('pagination not defined on the page')
    }

    const itemRefs = pagination?.getItems()
    if (!itemRefs) {
      throw new Error('no pagination items')
    }

    return itemRefs
  }

  useEffect(() => {
    try {
      const itemRefs = getItems(page, component)

      const { sortField, sortOrder } = parameters || {}
      if (!(sortField && sortOrder)) {
        console.error(
          'Sort requires both sort field and sort order, data will be returned they order it is given.'
        )
      }

      let sortedItemRef = itemRefs

      sortedItemRef = itemRefs.sort((pre, cur) => {
        let preItem = pre && page.getContent(pre)?.getData()
        preItem = (preItem && preItem[sortField]) || ''

        preItem = isNaN(preItem) ? preItem : parseFloat(preItem)

        let curItem = pre && page.getContent(cur)?.getData()
        curItem = (curItem && curItem[sortField]) || ''

        curItem = isNaN(curItem) ? curItem : parseFloat(curItem)

        if (sortOrder === 'asc') {
          if (preItem < curItem) return -1
          if (preItem > curItem) return 1
        } else {
          if (preItem > curItem) return -1
          if (preItem < curItem) return 1
        }
        return 0
      })
      const items = (sortedItemRef || item).map(itemMapper)
      setItems(items)
    } catch (error) {
      console.error(error)
    }
  }, [page, component, parameters])

  return items
}
