import React from 'react'
import axios from 'axios'
import { BrPageContext, BrComponent, BrPage } from '@bloomreach/react-sdk'
import layoutMap from './layouts/layoutMap'
import { getDebugConfig } from '../utility'
import {
  Accordion,
  ArticleDetail,
  FooterMenu,
  MainMenu,
  Header,
  Footer,
  Table,
  ArticleList,
  ContactList,
  Form,
  AddressList,
  TabList,
} from '../components'

const getBreConfig = () => {
  const _debugConfig = getDebugConfig()

  axios.defaults.withCredentials = true

  const _configuration = {
    path: _debugConfig.PATH,
    endpoint: _debugConfig.ENDPOINT,
    origin: _debugConfig.ORIGIN,
    debug: _debugConfig.DEBUG,
    authorizationHeader: 'HST-Authorization',
    httpClient: axios,
  }

  return _configuration
}

export default function Route({ location }) {
  const _mapping = {
    Accordion: Accordion,
    Article: ArticleDetail,
    ArticleList,
    Table,
    mainmenu: MainMenu,
    footermenu: FooterMenu,
    TabList,
    ContactList,
    Form,
    AddressList,
    Metadata: () => ""
  }

  const _config = getBreConfig()

  return (
    <BrPage configuration={_config} mapping={_mapping}>
      {
        <>
          <header className='header'>
            <nav className='header__nav u-paddingTop5gu u-paddingBottom7gu u-sm-paddingBottom3gu u-md-paddingBottom3gu'>
              <Header />
              <BrComponent path='header' />
            </nav>
          </header>
          <main className='u-posRelative'>
            <BrPageContext.Consumer>
              {page => {
                const layoutName = page.root.model.name

                const Layout = layoutMap.find(el => el.regex.test(layoutName)).container
                return <Layout props={page} />
              }}
            </BrPageContext.Consumer>
          </main>
          <footer>
            <BrComponent path='footer' />
            <Footer />
          </footer>
        </>
      }
    </BrPage>
  )
}
