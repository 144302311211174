const itemMapper = page => {
  return itemRef => {
    const item = itemRef && page.getContent(itemRef)

    const data = item.getData()

    const translatedData = {}

    translatedData.id = data.id
    translatedData.title = data?.title
    translatedData.subtitle = data.subtitle || data.subhead
    translatedData.publicationDate = data.publicationDate
    translatedData.time = data.time
    translatedData.html =
      data.html && typeof data.html === 'object' ? data.html.value : data.html || data.content
    translatedData.imageRef = data.image || data.heroImage
    translatedData.keys = data.keys
    translatedData.videoId = data.videoId
    translatedData.itemType = data.itemType
    translatedData.link = data.linkCompound
    translatedData.linkList = data.linkList

    const {
      id,
      title,
      subtitle,
      publicationDate,
      time,
      html,
      imageRef,
      keys,
      videoId,
      itemType,
      link,
      linkList,
    } = translatedData

    const image = imageRef && page?.getContent(imageRef)

    const imageUrl = image?.getOriginal().getUrl()
    const imageDescription = image?.getDescription()

    return {
      id,
      title,
      subtitle,
      publicationDate,
      time,
      html,
      imageUrl,
      imageDescription,
      keys,
      videoId,
      itemType,
      link,
      linkList,
      document: item,
    }
  }
}
export default itemMapper
