import React, { useState, useEffect } from 'react'

// const QA_EMAIL = 'Fn2128239.Ln2128239@qa.ampf.com'
const QA_EMAIL = 'Srinivas.Thoopukari@qa.ampf.com'
const PROD_EMAIL = 'SCTPPhelp@prepaidtuition.com'

const isLocal = () => {
  return window.location.port
}

const isDev = () => {
  return window.location.origin.indexOf('www.dev') > -1
}

const isQa = () => {
  return window.location.origin.indexOf('www.qa') > -1
}

const isQaWs3 = () => {
  return window.location.origin.indexOf('www-ws3.qa') > -1
}

const ErrorMessage = () => {
  return (
    <div className='u-flex u-flexAlignItemsCenter u-bgColorWhite u-border u-borderColorNeutralLight u-marginVert4gu u-padding3gu'>
      <span className='material-icons-round  u-marginRight2gu u-textColorAlert u-textSizeMsrp3'>
        error_outline
      </span>
      <div className='u-textSizeMsrm1 u-textColorLight'>
        <span className='u-textBold u-textSecondary'>
          We're experiencing technical difficulties.
        </span>{' '}
        Your message could not be submitted at this time. Please try again later.
      </div>
    </div>
  )
}

const SuccessMessage = () => {
  return (
    <div className='u-flex u-flexCol u-flexAlignItemsCenter u-marginTop5gu u-padding15gu u-bgColorWhite'>
      <div>
        <span className='material-icons-round  u-textColorAdele u-textSizeMsrp5' aria-hidden='true'>
          check_circle
        </span>
      </div>
      <h4 className='u-textColorBrand u-textSizeMsrp3'>Thank you for submitting your message</h4>
      <div className='u-marginTop2gu u-textSizeMsrp1'>
        Our team will respond as soon as possible.
      </div>
    </div>
  )
}

export function Form({ page, component }) {
  const [isValidating, setIsValidating] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isFail, setIsFail] = useState(false)
  const [form, setForm] = useState({
    fullName: null,
    email: null,
    message: null,
  })
  const onChangeForm = (e, name) => {
    setForm({ ...form, [name]: e.target.value })
  }

  const onClickSubmit = e => {
    e.stopPropagation()
    e.preventDefault()
    if (isNameError(true) || isEmailError(true) || isMessageError(true)) {
      setIsValidating(true)
      setHasError(true)
      return
    } else {
      setIsValidating(false)
      setHasError(false)
    }

    const to = []

    if (isLocal() || isDev() || isQa() || isQaWs3()) {
      to.push(QA_EMAIL)
    } else {
      to.push(PROD_EMAIL)
    }

    const emailPayLoad = {
      type: 'email',
      data: {
        from: form.email,
        to: to,
        subject: 'SCPrepaid Contact Us Form',
        body: `From: ${form.fullName} <br/> Message: ${form.message}`,
      },
    }

    const url = '/api/email'

    setIsSubmitting(true)
    fetch(url, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
      body: JSON.stringify(emailPayLoad),
      credentials: 'include',
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`)
        }
        return response.json()
      })
      .then(data => {
        setIsSuccess(true)
        setIsFail(false)
      })
      .catch(error => {
        setIsFail(true)
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  const isNameError = v => {
    return (isValidating || v) && !form.fullName
  }

  const isEmailError = v => {
    return (
      (isValidating || v) &&
      (!form.email || form.email.indexOf('@') < 0 || form.email.indexOf('.') < 0)
    )
  }

  const isMessageError = v => {
    return (isValidating || v) && !form.message
  }

  return (
    <div>
      <div className='u-flex'>
        <div className='u-sizeFull u-paddingRight4gu'>
          {isFail && <ErrorMessage />}
          {!isFail && isSuccess ? (
            <SuccessMessage />
          ) : (
            <form className='u-border u-borderRoundedSmall u-padding4gu'>
              <div>
                <label htmlFor='scpp_fullName' className='Form-label'>
                  Full name
                </label>
                <div className='Input-group'>
                  <input
                    id='scpp_fullName'
                    className={`Input ${
                      isNameError() ? 'Input--error' : ''
                    } u-marginTop1gu  u-borderRoundedSmall`}
                    type='text'
                    name='fullName'
                    onChange={e => onChangeForm(e, 'fullName')}
                  />
                </div>
                <p className='Form-help u-textColorAlert'>
                  {isNameError() ? <>Please enter a full name.</> : <>&nbsp;</>}
                </p>
              </div>
              <div>
                <label htmlFor='scpp_email' className='Form-label'>
                  Email
                </label>
                <div className='Input-group'>
                  <input
                    id='scpp_email'
                    className={`Input ${
                      isEmailError() ? 'Input--error' : ''
                    } u-marginTop1gu  u-borderRoundedSmall`}
                    type='text'
                    name='email'
                    onChange={e => onChangeForm(e, 'email')}
                  />
                </div>
                <p className='Form-help u-textColorAlert'>
                  {isEmailError() ? <>Please enter a valid email.</> : <>&nbsp;</>}
                </p>
              </div>

              <div className='scpp-message-wrap'>
                <label htmlFor='scpp_message' className='Form-label'>
                  Message
                </label>
                <div className='Input-group'>
                  <textarea
                    id='scpp_message'
                    className={`Input ${
                      isMessageError() ? 'Input--error' : ''
                    } u-marginTop1gu  u-borderRoundedSmall`}
                    type='text'
                    name='message'
                    rows='10'
                    onChange={e => onChangeForm(e, 'message')}
                  ></textarea>
                  {isMessageError() && <span className='Input-icon Icon'>&#xE002;</span>}
                </div>
                <p className='Form-help u-textColorAlert'>
                  {isMessageError() ? <>Please enter a message.</> : <>&nbsp;</>}
                </p>
              </div>

              <div>
                <button
                  className='Button Button--primary Button--block'
                  data-tracking='primary'
                  onClick={onClickSubmit}
                  disabled={isSubmitting}
                >
                  Submit
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  )
}
