import React, { useEffect } from 'react'
import itemMapper from './paginationModel'
import { usePagination } from '../../utility'

export const AddressList = ({ page, component }) => {
  const _parameters = component.getParameters()

  const pagination = usePagination({
    page,
    component,
    parameters: _parameters,
    itemMapper: itemMapper(page),
  })

  return (
    <div className='addressList'>
      <h3 className='u-marginBottom5gu'>Locations</h3>
      <dl className='Grid u-flexJustifyBetween'>
        {pagination?.map(item => (
          <div
            key={item.type}
            className='address u-size3of12 u-sm-sizeFull  u-sm-marginBottom8gu  u-paddingLeft5gu'
          >
            <dt className='addressType u-textBold u-posRelative u-marginBottom3gu'>
              <span className='material-icons-round u-inlineBlock address-icon u-posAbsolute'>
                location_on
              </span>
              {item.type}
            </dt>
            <dd className='addressName  u-textBold u-textColorLight u-marginBottom3gu'>
              {item.to}
            </dd>
            {item.address ? <dd className='addressField'> {item.address} </dd> : ''}
            {item.address1 ? <dd className='addressField'> {item.address1} </dd> : ''}
            {item.address2 ? <dd className='addressField'> {item.address2} </dd> : ''}
          </div>
        ))}
      </dl>
    </div>
  )
}
