import React, { useState } from 'react'

let reportToDownload = []

export const Dropdown = ({ activeArticles, allReports }) => {
  const [isDisabled, setDisabled] = useState('disabled')

  function getSelected() {
    const select = document.getElementById('report_year_dropdown')
    const activeOption = select.options[select.selectedIndex].value

    if (activeOption != 'Choose a report year') setDisabled('enabled')
    else setDisabled('disabled')

    //if year and type in selectbox value matches the value in allReports set it as the report to download
    for (let key in allReports) {
      if (allReports[key].value === activeOption) {
        reportToDownload = allReports[key]
      }
    }
  }

  function getDownload() {
    let path = ''
    if (window.location.href.indexOf('/site/scprepaid') >= 0) {
      path = '/site/scprepaid'
    } else {
      path = window.location.origin
    }

    const download = document.createElement('a')
    download.href = path + reportToDownload.link.link[0].string
    download.download =
      reportToDownload.title + ' ' + reportToDownload.itemType + ' report' + '.pdf'
    download.target = '_blank'
    download.click()
  }

  return (
    <div className='u-lg-flex u-marginTop5gu'>
      <label className='Form-label u-hidden' htmlFor='report_year_dropdown'>
        dropdown label
      </label>
      <div className='Dropdown u-size12of12 u-lg-size3of12 u-borderRight0'>
        <label className='u-hiddenVisually' htmlFor='report_year_dropdown'>
          report year dropdown
        </label>
        <select
          className='Dropdown-menu u-textCapitalize u-borderRight0 u-sm-marginBottom4gu u-md-marginBottom4gu'
          id='report_year_dropdown'
          onChange={() => getSelected()}
        >
          <option key='0' defaultValue='Choose a report year'>
            Choose a report year
          </option>
          {activeArticles
            .sort((a, b) => (a.title < b.title ? 1 : -1))
            .map(item => (
              <option key={item.id} value={item.value}>
                {item.title}
              </option>
            ))}
        </select>
      </div>
      {
        <button
          disabled={isDisabled === 'disabled' ? true : ''}
          onClick={() => getDownload()}
          className='Button Button--secondary button primary-button u-textCapitalize u-size8of8 u-lg-size1of8 u-borderLeft0'
        >
          <span className='material-icons-round u-marginRight2gu'>download</span>
          Download
        </button>
      }
    </div>
  )
}

export default Dropdown
