import React from 'react';
import Dropdown from './dropdown.jsx';
export const TabContent = ({ activeArticles, allReports }) => {
  return (
    <div>
      <p>{activeArticles[0]?.subtitle}</p>
      {<Dropdown activeArticles={activeArticles} allReports={allReports} />}
    </div>
  )
}

export default TabContent
