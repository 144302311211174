import React from 'react'
import { BrComponent } from '@bloomreach/react-sdk'

const DoubleColumn = props => {
  const { page } = props
  return (
    <div>
      <div className='u-sizeConstrained'>
        <BrComponent path='above' />
      </div>
      <div className='u-flex u-sizeConstrained u-flexWrap'>
        <div className={page?.isPreview() ? 'u-flexGrow2' : 'u-size1of2 u-sm-sizeFull'}>
          <BrComponent path='main' />
        </div>
        <div
          className={`u-paddingLeft5gu ${
            page?.isPreview() ? 'u-flexGrow1' : 'u-size1of2 u-sm-sizeFull'
          }`}
        >
          <BrComponent path='right' />
        </div>
      </div>
    </div>
  )
}
export default DoubleColumn
