import React, { useState, useEffect, useContext, useRef } from 'react'
import { Link } from 'react-router-dom'
import { getPathAndEndpoint } from '../../utility/config/configuration'

import {
  /* BrComponentContext, */ BrManageMenuButton /* , BrPageContext */,
} from '@bloomreach/react-sdk'
import { USER_LOGIN_LINK, ADMIN_LOGIN_LINK, CONTACT_US_LINK } from '../../utility/links/links'

export const MainMenu = ({ page, component }) => {
  // console.log('PAGE >>>', page)
  // console.log('COMPONENT >>>', component)
  const [menu, setMenu] = useState(null)

  useEffect(() => {
    const _menuRef = component?.getModels().menu
    const _menu = _menuRef && page?.getContent(_menuRef)
    if (_menu) {
      setMenu(_menu)
    }
  }, [page, component])

  return (
    <div className='MainMenu bottom-header u-paddingRight2gu'>
      <MobileMenu page={page} component={component} menu={menu} />
      <WebMenu page={page} component={component} menu={menu} />
    </div>
  )
}

const WebMenu = ({ page, component, menu }) => {
  const refMenuNavigation = useRef()
  const [navigationSelected, setNavigationSelected] = useState('')

  useEffect(() => {
    document.addEventListener('mousedown', event => {
      if (
        refMenuNavigation &&
        refMenuNavigation.current &&
        !refMenuNavigation.current.contains(event.target)
      ) {
        setNavigationSelected('')
      }
    })
  })

  const onClickHideMenuExpand = () => {
    setNavigationSelected(false)
  }

  const onClickSetNavigationExpand = event => {
    const _navigationSelected = event.currentTarget.dataset.navigation

    if (navigationSelected === _navigationSelected) {
      setNavigationSelected('')
    } else {
      setNavigationSelected(_navigationSelected)
    }
  }

  return (
    <>
      {menu?.getItems()?.length > 0 && (
        <div
          className='bottom-header__menu-expand u-sizeConstrained'
          style={{ gridTemplateColumns: `repeat(${menu?.getItems()?.length}, 1fr)` }}
          ref={refMenuNavigation}
        >
          {menu?.getItems().map((item, index) => (
            <MenuItem
              key={index}
              item={item}
              navigationSelected={navigationSelected}
              onClickHideMenuExpand={onClickHideMenuExpand}
              onClickSetNavigationExpand={onClickSetNavigationExpand}
              page={page}
              isRoot
            />
          ))}
        </div>
      )}
    </>
  )
}

const MenuItem = ({
  item,
  page,
  navigationSelected,
  onClickHideMenuExpand,
  onClickSetNavigationExpand,
  isRoot,
}) => {
  const name = item.getName()
  const description = item.getParameters()?.description
  const icon = item.getParameters()?.icon
  const iconAriaLabel = item.getParameters()?.iconAriaLabel
  const link = item.getLink()
  const childs = item.getChildren()

  const showItemDetails = () => {
    return (
      <>
        {link ? (
          isRoot ? (
            <a
              className='nav-button menu-expand-button u-textColorDefault u-linkClean u-textSizeMsrp1 u-textSecondary u-paddingVert3gu'
              href={`${getPathAndEndpoint().path}${link?.href}`}
              onClick={onClickHideMenuExpand}
            >
              {name}
            </a>
          ) : (
            <a
              className='menu-expand-options__group--link u-linkClean u-textColorDefault'
              href={`${getPathAndEndpoint().path}${link?.href}`}
              onClick={onClickHideMenuExpand}
            >
              <span className='material-icons-round md-30' role='img' aria-label={iconAriaLabel}>
                {icon}
              </span>

              <div className='menu-expand-options__group--link--content'>
                <h3>{name}</h3>
                <p>{description}</p>
              </div>
            </a>
          )
        ) : (
          <button
            className='nav-button menu-expand-button u-textSizeMsrp1 u-textSecondary u-paddingVert3gu'
            onClick={onClickSetNavigationExpand}
            data-navigation={name}
          >
            {' '}
            {name}
            {navigationSelected === name ? (
              <span className='material-icons-round md-30' role='img' aria-label='expand less icon'>
                {' '}
                expand_less{' '}
              </span>
            ) : (
              <span className='material-icons-round md-30' role='img' aria-label='expand more icon'>
                {' '}
                expand_more{' '}
              </span>
            )}
          </button>
        )}
      </>
    )
  }

  const showChilds = () => {
    return (
      <>
        {navigationSelected === name ? (
          <div className='menu-expand-options'>
            <div className='menu-expand-options__group'>
              {childs?.map((child, index) => (
                <MenuItem
                  key={index}
                  item={child}
                  navigationSelected={navigationSelected}
                  onClickHideMenuExpand={onClickHideMenuExpand}
                  onClickSetNavigationExpand={onClickSetNavigationExpand}
                  page={page}
                />
              ))}
            </div>
          </div>
        ) : (
          ''
        )}
      </>
    )
  }

  return (
    <>
      {childs?.length > 0 ? (
        <div>
          {showItemDetails()}
          {showChilds()}
        </div>
      ) : (
        <>{showItemDetails()}</>
      )}
    </>
  )
}

const MobileMenu = ({ page, component, menu }) => {
  const [showMobileSideBar, setShowMobileSideBar] = useState(false)
  const onClickHamburgerMenu = () => {
    setShowMobileSideBar(true)
  }
  const onKeyDownHamburgerMenu = event => {
    event.charCode == 13 ? setShowMobileSideBar(true) : null
  }
  const onClickHideSidebar = () => {
    setShowMobileSideBar(false)
  }

  const [mobileNavigation, setMobileNavigation] = useState('')
  const refMobileNavigation = useRef()

  useEffect(() => {
    document.addEventListener('mousedown', event => {
      if (
        refMobileNavigation &&
        refMobileNavigation.current &&
        !refMobileNavigation.current.contains(event.target)
      ) {
        setMobileNavigation('')
      }
    })
  })

  const onClickExpandNavigation = event => {
    const mobileNavigationSelected = event.currentTarget.dataset.navigation
    {
      mobileNavigation === mobileNavigationSelected
        ? setMobileNavigation('')
        : setMobileNavigation(mobileNavigationSelected)
    }
  }

  const onClickHideMenuExpand = () => {
    setMobileNavigation(false)
    setShowMobileSideBar(false)
  }

  return (
    <>
      <div
        tabIndex={0}
        onClick={onClickHamburgerMenu}
        onKeyDown={onKeyDownHamburgerMenu}
        className='hamburger-menu u-floatRight u-posRelative'
      >
        <span
          className='material-icons-round md-30 u-textSizeMsrp4'
          aria-label='Click to expand mobile menu'
        >
          {' '}
          menu{' '}
        </span>
      </div>

      {showMobileSideBar && (
        <>
          <div className='mobile-sidebar u-padding5gu u-posFixed u-sm-sizeFull'>
            <div className='mobile-sidebar__close-icon u-posRelative'>
              <span
                className='material-icons-round md-30 pointer'
                aria-label='close icon'
                onClick={onClickHideSidebar}
              >
                {' '}
                close{' '}
              </span>
            </div>

            <div className='mobile-sidebar__menu-expand'>
              <div ref={refMobileNavigation}>
                <div className='mobile-menu-expand'>
                  {menu?.getItems().map((item, index) => (
                    <MobileMenuItem
                      key={index}
                      item={item}
                      mobileNavigation={mobileNavigation}
                      onClickHideMenuExpand={onClickHideMenuExpand}
                      onClickExpandNavigation={onClickExpandNavigation}
                      page={page}
                      isRoot
                    />
                  ))}
                </div>
              </div>
            </div>

            <div className='mobile-sidebar__buttons'>
              <button
                className='button primary-button'
                onClick={() => window.open(USER_LOGIN_LINK, '_blank')}
              >
                {' '}
                SCTPP Customer Login{' '}
              </button>

              <button
                className='button secondary-button'
                onClick={() => window.open(ADMIN_LOGIN_LINK, '_blank')}
              >
                {' '}
                School Administrator Login{' '}
              </button>

              <button
                className='button tertiary-button'
                onClick={() => window.open(CONTACT_US_LINK, '_self')}
              >
                {' '}
                Contact Us{' '}
              </button>
            </div>
          </div>

          <div className='mobile-sidebar-overlay' onClick={onClickHideSidebar}></div>
        </>
      )}
    </>
  )
}

const MobileMenuItem = ({
  item,
  page,
  mobileNavigation,
  onClickExpandNavigation,
  onClickHideMenuExpand,
  isRoot,
}) => {
  const name = item.getName()
  const description = item.getParameters()?.description
  const icon = item.getParameters()?.icon
  const iconAriaLabel = item.getParameters()?.iconAriaLabel
  const link = item.getLink()
  const childs = item.getChildren()

  const showItemDetails = () => {
    return (
      <>
        {link ? (
          isRoot ? (
            <div className='mobile-menu-expand u-borderBottom u-borderTop0 u-borderLeft0 u-borderRight0'>
              <a
                href={`${getPathAndEndpoint().path}${link?.href}`}
                className='nav-button mobile-menu-expand__single-nav-link u-textColorDefault u-textSecondary u-paddingVert5gu u-textSizeMsrp2'
                onClick={onClickHideMenuExpand}
              >
                <span className='material-icons-round md-30' aria-label={iconAriaLabel}>
                  {icon}
                </span>
                {name}
              </a>
            </div>
          ) : (
            <a
              href={`${getPathAndEndpoint().path}${link?.href}`}
              className='mobile-menu-expand__menu-options--link u-linkClean u-textColorDefault'
              onClick={onClickHideMenuExpand}
            >
              <span className='material-icons-round md-30' aria-label={iconAriaLabel}>
                {icon}
              </span>
              <h3 className='u-textSizeMsrp1'>{name}</h3>
            </a>
          )
        ) : (
          <>
            <button
              className='nav-button mobile-menu-expand__button u-borderBottom u-borderTop0 u-borderLeft0 u-borderRight0 u-linkClean u-textSecondary u-paddingVert3gu u-textSizeMsrp2'
              onClick={onClickExpandNavigation}
              data-navigation={name}
            >
              {' '}
              {name}
              {/* ICON: EXPAND MORE AND EXPAND LESS */}
              {mobileNavigation === name ? (
                <span
                  className='material-icons-round md-30'
                  aria-label='Select to hide menu options'
                >
                  {' '}
                  expand_less{' '}
                </span>
              ) : (
                <span
                  className='material-icons-round md-30'
                  aria-label='Select to see menu options'
                >
                  {' '}
                  expand_more{' '}
                </span>
              )}
            </button>
          </>
        )}
      </>
    )
  }

  const showChilds = () => {
    return (
      <>
        {mobileNavigation === name ? (
          <div className='mobile-menu-expand__options'>
            {childs?.map((child, index) => (
              <MobileMenuItem
                key={index}
                item={child}
                mobileNavigation={mobileNavigation}
                onClickExpandNavigation={onClickExpandNavigation}
                onClickHideMenuExpand={onClickHideMenuExpand}
                page={page}
              />
            ))}
          </div>
        ) : (
          ''
        )}
      </>
    )
  }

  return (
    <>
      {childs?.length > 0 ? (
        <div>
          {showItemDetails()}
          {showChilds()}
        </div>
      ) : (
        <>{showItemDetails()}</>
      )}
    </>
  )
}
